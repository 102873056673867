.empty-owned-actions-card-body {
  padding: 6.125rem;
}
.create-action-btn-wrapper {
  padding-top: 0.938rem;
  padding-bottom: 0.938rem;
}
.owned-actions-optional-badge {
  margin-right: 0.625rem;
}
.modify-card-details-btn {
  background-color: rgba(0, 0, 0, 0);
  border-width: 0rem;
  font-size: 1.125rem;
  color: #848484;
}
.nowrap {
  white-space: nowrap;
}
