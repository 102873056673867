.empty-right-menu-text {
  text-align: center;
  padding-top: 50%;
  font-size: 16px;
}
.message-label {
  font-size: 12px;
  margin-bottom: 0px;
}
.message-code-block {
  width: 100%;
  min-width: 100%;
  background-color: #f1f1f1;
  padding: 5px 12px;
  color: #2e2e2e;
  font-weight: 700;
  margin-bottom: 15px;
}
.message-code {
  color: #2e2e2e;
}
.next-steps-wrapper {
  margin-bottom: 15px;
}
.archive-btn {
}
.archive-btn-icon {
  padding-right: 5px;
  top: 3px;
  position: relative;
}
