.estimated-spinner {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-width: 2px;
  vertical-align: middle;
}
.estimated-loading-text-wrapper {
  display: inline-block;
  width: calc(100% - 30px);
  padding: 5px;
  padding-left: 20px;
}
.estimated-loading-text {
  font-size: 16px;
  margin-bottom: 0px;
}
.estimated-loading-subtext {
  font-size: 12px;
}
.estimated-loading-badge {
  color: #fff;
  font-size: 10px;
  margin-left: 5px;
}
.add-tax-line-item-btn {
  border-width: 0px;
}
