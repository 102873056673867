.shopify-page-row {
  padding-top: 10%;
}
.shopify-page-logo {
  height: 100px;
}
.shopify-page-text-style {
  font-size: 18px;
  padding-top: 40px;
  padding-bottom: 10px;
}
.shopify-page-go-to-btn-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}
.shopify-page-go-to-btn {
  background-color: #0489b1;
  color: #fff;
  padding: 15px 50px;
  font-size: 20px;
  border-radius: 5px;
}
.shopify-page-go-to-btn:hover {
  color: #fff;
}
.shopify-page-status-wrapper {
  width: 100%;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 50px;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}
.shopify-page-status-text {
  font-size: 16px;
}
.shopify-page-status {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
  right: 5px;
}
.status-green {
  background-color: #04b404;
}
.status-red {
  background-color: #b40431;
}
