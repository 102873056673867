.search-row {
  margin-top: -20px;
}
.search-filter-wrapper {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 0px;
  vertical-align: top;
}
.search-filter-input-wrapper {
  width: 100%;
  max-width: 400px;
}
.search-filter-select-wrapper {
  width: 100%;
  max-width: 300px;
}
.search-filter-input {
  height: 38px;
  padding: 4px 7px;
  position: relative;
}
.search-filter-show-archived-label {
  width: 100%;
  font-size: 10px;
  padding-left: 8px;
}
.search-filter-show-archived-input {
  margin-right: 3px;
  top: 3px;
  position: relative;
}
