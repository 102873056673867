table.VariantsTable thead th,
table.VariantsTable tbody td {
  border-left: none;
  border-right: none;
}

table.VariantsTable tbody tr td {
  padding: 0.8rem;
}

table.VariantsTable thead tr:last-child th,
table.VariantsTable tbody tr:last-child td {
  border-bottom: none;
}

table.VariantsTable tbody tr:hover {
  cursor: pointer;
}
