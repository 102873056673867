.DoneCheckmark {
  border: 2px solid var(--light);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DoneCheckmark__checked {
  border: 2px solid var(--primary);
  background: var(--primary);
  color: white;
}

.DoneCheckmark__checked i {
  font-weight: 700;
}

.DoneCheckmarkCentered {
  margin: auto;
}
