.single-action-wrapper {
  padding: 0.5rem 0rem;
  padding-bottom: 0rem;
}
.single-action-title {
  margin-right: 0.625rem;
}
.file-upload-reset-btn {
  font-size: 0.563rem;
  padding: 0.063rem 0.313rem;
  margin-left: 0.313rem;
}
.invalid-type-wrapper {
  width: 100%;
  text-align: center;
}
.invalid-type-text {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.invalid-type-subtext {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.acknowledge-completed-text {
  font-size: 0.875rem;
  margin-top: -0.313rem;
}
.acknowledge-completed-inner-text {
  color: #04b431;
}
.acknowledge-completed-icon {
  color: #04b431;
  margin-right: 0.313rem;
}
