table.ProductAttributesTable thead th,
table.ProductAttributesTable tbody td {
  border-left: none;
  border-right: none;
}

table.ProductAttributesTable tbody tr td {
  padding: 0.8rem;
}

table.ProductAttributesTable thead tr:last-child th,
table.ProductAttributesTable tbody tr:last-child td {
  border-bottom: none;
}
