.error-badge {
  color: #e63757 !important;
}

.sent-badge {
  color: #c79500 !important;
}

.success-badge {
  color: #04b431 !important;
}

tbody > tr.rowHeader {
  background-color: #f9fbfd;
}
