.error-row {
  cursor: pointer;
}
.error-row-selected {
  background-color: #f1f1f1;
}
.error-message-wrapper {
  width: 100%;
}
.severity-wrapper {
  padding-top: 5px;
  display: inline-block;
  padding-right: 10px;
}
.error-code-badge {
  margin-right: 10px;
}
.date-span {
  font-size: 10px;
  display: inline-block;
}
.no-results-text {
  width: 100%;
  text-align: center;
  padding-top: 50px;
  font-size: 16px;
  margin-bottom: 0px;
}
.no-results-subtext {
  width: 100%;
  text-align: center;
  font-size: 12px;
}
