.ConvictAlert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.ConvictAlert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition:
    opacity 300ms,
    transform 300ms;
}
.ConvictAlert-exit {
  opacity: 1;
}
.ConvictAlert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition:
    opacity 300ms,
    transform 300ms;
}
